<template>
	<v-text-field class="right-aligned"
        hide-details dense outlined
        :label="label"
        :ref="name"
        :value="value"
        :placeholder="label"
        :prefix="prefix[format]"
        :suffix="suffix[format]"
        @input="$emit('changed', $event)"
        @keypress="isAllowed($event)"
        autocomplete="new-password"
    ></v-text-field>
</template>
<script>
export default {
    name: 'NumberInput',
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
        prefix: { 'default': '', 'number': '#', 'date': '', 'decimal': '#', 'euro': '#', 'proz': '#' },
        suffix: { 'default': '', 'number': '', 'date': '', 'decimal': '', 'euro': '€', 'proz': '%' },
    }),
    mounted() {
        if(this.reverse[this.format]) {
            let swap = this.prefix
            this.prefix = this.suffix
            this.suffix = swap
        }
        //console.log('NumberInput[' + this.name + ']:', this.format, this.reverse[this.format], this.prefix[this.format], this.suffix[this.format])
    },
    methods: {
        isAllowed(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[0-9\,\-]+$/.test(char)) return true; // Match with regex 
            else e.preventDefault(); // If not match, don't add to input text
        }
    },
}
</script>
<style scoped>
.right-aligned >>> input {
    text-align: end
}
</style>